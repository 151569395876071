export default {
//     CHAINID:56,
//     TOKEN_ADDRESS:"0x421b9b6Ee30b17EC9D55BbBfFc1c9cD3cfD0c2f2",
//     MLM_ADDRESS:"0x1D85B616C71587130068CEFB9aBE1E2f92F41B46",
//     DEFAULT_REF:"0xFEFAc8Bc374460aca94A25E0A2E101B33b5B8e08",
//     BUSD:"0xe9e7cea3dedca5984780bafc599bd69add087d56",
//     EXCHANGE:"0xfEaf5c663585f77E04e51eEe0c9A8cA74C74b884",
//     HALF_YEAR_APY: 10,

// // 





    CHAINID:1370,
    TOTAL_ROI: 36,
    ANNUAL_ROI: 18,
    STAKING_ADDRESS:"0xE8E4C68aa2De7E3462a48ADF06bc13d16407072D",
    RPC:"https://blockchain.ramestta.com",
    PRICE: 0.12,
    COINPAYMENT_SERVER:"https://presale.ramaera.tk"
}